<template>
  <div  class="px-15px px-lg-25px">
     <div class="aiz-titlebar text-left mt-2 mb-3">
      <div class="row align-items-center">
        <div class="col-auto">
            <h1 class="h3">{{ $t('gou-mai-zhi-tong-che-li-shi-ji-lu') }}</h1>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
          <h5 class="mb-0 h6">{{ $t('suo-you-zhi-tong-che-li-shi-ji-lu') }}</h5>
      </div>
      <div class="card-body">
        <table class="table  mb-0">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ $t('dian-pu-deng-ji') }}</th>
              <th>{{ $t('tao-can-jia-ge') }}</th>
              <th>{{ $t('gou-mai-shi-jian') }}</th>
              <th>{{ $t('dao-qi-shi-jian') }}</th>
              <th>{{ $t('fu-kuan-fang-shi') }}</th>
              <th>{{ $t('tui-guang-wei') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in tableData" :key="i">
              <td>
                  {{ i + 1 }}
              </td>
              
              <td>{{item.ShopTrain ? item.ShopTrain.Name : ''}}</td>
              <td>{{item.ShopTrain.UpgradeMoney ? item.ShopTrain.UpgradeMoney : $t('mian-fei')}}</td>
              <td>{{item.CreatedAt}}</td>
              <td>{{getEndTime(item.CreatedAt)}}</td>
              <td>{{ $t('qian-bao') }}</td>
              <td>{{item.ShopTrain.GoodsLimit}}</td>
              
            </tr>
            </tbody>
        </table>
        <div class="aiz-pagination" v-if="page.total > 1">
          <pageBar :totalPage="page.total" :page="page.page"></pageBar>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Popup } from 'vant'
import pageBar from '@/components/page'
import { shopTrainUpgradeLog } from '@/api/seller'
import moment from 'moment'
export default {
  components: {
    pageBar,
    Popup
  },
  data() {
    return {
      showPopup: false,
      form: {
        deliverStatus: '',
        payStatus: '',
        payType: ''
      },
      statusList1: [],
      statusList2: [],
      hasPackage: true,
      packageUrl: require('../../../assets/imgs/package.png'),
      tableData: [],
      page: {
        page: 1,
        limit: 10,
        total: 1
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      shopTrainUpgradeLog().then(res => {
        this.tableData = res.data.Items
        this.page.total = res.data.Pagination ? res.data.Pagination.totalPage : 1
      })
    },
    openModal() {
      this.showPopup = true
    },
    cancel() {
      this.showPopup = false
    },
    getEndTime(time) {
      return moment(time).add(1, 'years').format('YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>